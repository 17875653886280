import React, { useState } from "react";
import { VerifyMethodEnum, getVerifyMethodLabel } from "../../enums";
import { BASEAPI, CHRISCO_NUMBER } from "../../constants";
import { PostJson } from "../../helpers/fetch_helpers";
import { Alert, Button, Form, FormGroup, Input, Label, Spinner } from "reactstrap";
import { isEmpty } from "lodash";

export default function ViewInitial({nextStep, updatedMemberDetails, verificationMessageSent}) {
    let [eaid, setEaid] = useState();
    let [memberNumber, setMemberNumber] = useState();
    let [memberLoading, setMemberLoading] = useState(false);
    let [memberDetail, setMemberDetail] = useState(null);
    let [verifyMethodType, setVerifyMethodType] = useState(VerifyMethodEnum.Email);
    
    let [eaidError, setEaidError] = useState();
    let [sendVerificationError, setSendVerificationError] = useState();

    async function submitEaid() {
        setEaidError(null);
        setMemberLoading(true)

        var uri = BASEAPI + "/api/member/VerifyEaid/" + eaid;
        var result = await PostJson(uri)
            .catch(function(error){
                //console.log(error);
                setEaidError("An error occurred verifying your External Account Id: " + error.message + ". If this continues to occur, please call " + CHRISCO_NUMBER + " to speak to one of the team.")
            }).finally(() => {
                //stop spinner
                setMemberLoading(false)
            })

            if (result.hasMemberDetails) {
                setMemberDetail(result);
                updatedMemberDetails(result);
            } else {
                //show error
            }
    }

    async function submitMemberNumber() {
        setEaidError(null);
        setMemberLoading(true)

        var uri = BASEAPI + "/api/member/VerifyMember/" + memberNumber;
        var result = await PostJson(uri)
            .catch(function(error){
                //console.log(error);
                setEaidError("An error occurred verifying your Member Number: " + error.message + ". If this continues to occur, please call " + CHRISCO_NUMBER + " to speak to one of the team.")
            }).finally(() => {
                //stop spinner
                setMemberLoading(false)
            })

            if (result.hasMemberDetails) {
                setMemberDetail(result);
                updatedMemberDetails(result);
            } else {
                //show error
            }
    }

    function changeVerifyMethod(event) {
        if (event.target.value) {
            var verifyMethodTypeId = parseInt(event.target.value);
            setVerifyMethodType(verifyMethodTypeId);
        } else {
            setVerifyMethodType(VerifyMethodEnum.Email);
        }
    }

    function needsChoice(member) {
        return member.hasEmail && member.hasMobile;
    }

    async function sendVerification() {
        setSendVerificationError(null);

        var uri = BASEAPI + "/api/member/SendVerificationForMember/";
        var data = {
            memberNumber: memberDetail.memberNumber,
            messageType: verifyMethodType
        }
        var result = await PostJson(uri, data)
            .catch(function(error){
                //console.log(error);
                setSendVerificationError("An error occurred sending your verification code: " + error.message + ". If this continues to occur, please call " + CHRISCO_NUMBER + " to speak to one of the team.");
            })

        if (result) {
            verificationMessageSent(verifyMethodType);
            nextStep();
        } else {
            setSendVerificationError("An error occurred sending the verification code.  Please call " + CHRISCO_NUMBER + " to speak to one of the team.");
        }
    }

    return (
        <div className="workflowContainer">
        <div>
            To view your cards and transactions we need to verify you against the details stored for one of your cards, or your Chrisco Membership.  
            Please enter either the External Account Id (EAID) of one of your cards, or your Chrisco Member Number below and submit.
        </div>
        <div className="formGroup">
            <div className="groupTitle">Account Information</div>
            <Alert color="warning">Please enter either your Chrisco Member Number or one of your External Account Id (EAID) in order to begin the process of viewing your Account Information</Alert>
            <Form>
                <FormGroup>
                    <Label for="eaid">External Account Id (EAID): </Label>
                    <Input type="string" id="eaid" onChange={e => setEaid(e.target.value)} />
                </FormGroup>
                <FormGroup>
                    <Button color="danger" disabled={memberLoading || memberDetail || isEmpty(eaid)} onClick={submitEaid}>Submit</Button>
                </FormGroup>
                <FormGroup>
                    <Label for="memberNumber">Chrisco Member Number: </Label>
                    <Input type="string" id="membeNumber" onChange={e => setMemberNumber(e.target.value)} />
                </FormGroup>
                <FormGroup>
                    <Button color="danger" disabled={memberLoading || memberDetail || isEmpty(memberNumber)} onClick={submitMemberNumber}>Submit</Button>
                </FormGroup>
                {eaidError &&
                <Alert color="danger">{eaidError}</Alert>}
            </Form>
            {memberLoading && 
                <div>
                    Searching for Account Details... <Spinner color="Primary" />
                </div>}
            {memberDetail && 
            <Alert color="info">Account details found...</Alert>
            }
        </div>
        {memberDetail && 
            <div className="formGroup">
                <div className="groupTitle">Member Verification</div>
                    <div>In order to confirm that you control the membership associated with this Chrisco Member Number/Card, you will be sent a verification code to the details stored on your membership. </div>
                    {memberDetail && needsChoice(memberDetail) && 
                        <div>Please choose which method you would prefer to recieve your verification code: 
                            <FormGroup inline>
                                <FormGroup tag="fieldset" onChange={changeVerifyMethod}>
                                {/* <legend>Verification Method Type: </legend> */}
                                <FormGroup check inline>
                                    <Input name="verifyMethodType" type="radio" value={1} checked={verifyMethodType == 1} /> 
                                    {' '} 
                                    <Label check>E-Mail</Label>
                                </FormGroup>
                                <FormGroup check inline>
                                    <Input name="verifyMethodType" type="radio" value={2} checked={verifyMethodType == 2} /> 
                                    {' '} 
                                    <Label check>SMS</Label>
                                </FormGroup>
                            </FormGroup>
                        </FormGroup>
                        </div>}
                        <Alert color="info">
                            <div>You will recieve your verification code via: {getVerifyMethodLabel(verifyMethodType)} </div>
                        </Alert>
                    <FormGroup inline>
                        <Button color="danger" onClick={sendVerification}>Send Verification Code</Button>
                    </FormGroup>
                    {sendVerificationError && 
                            <Alert color="danger">{sendVerificationError}</Alert>
                        }
             </div>
        }
    </div>
    )
}