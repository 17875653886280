import React from 'react';
import { Button, Card, CardBody, CardText, CardTitle, Col, Container, FormGroup, Row } from 'reactstrap';
import { useHistory } from "react-router";
import topBanner from "../images/2024-Banner-HomePage-MASTERCARD.jpg";
import { CHRISCO_FACEBOOK, CHRISCO_INSTAGRAM, CHRISCO_NUMBER, CHRISCO_SITE, TNCS_LINK, CARD_LINK } from '../constants';
import { FaFacebook, FaInstagramSquare } from 'react-icons/fa';

export function Home() {
    var history = useHistory();

    function startActivate() {
        history.push("/activate");
    }

    function viewTransactions() {
        history.push("/viewTransactions");
    }

    return (
        <Container fluid>
            <Row>
                <Col><img className="topBannerImage" src={topBanner} alt="Chrisco Hampers" /></Col>
            </Row>
            <Row>
                <Col>
                    <div className="float-left"><Button color="link" href={TNCS_LINK} target='_blank'> Terms and Conditions</Button></div>
                    <div className="float-right contentHeaderSubtitle">&nbsp;{CHRISCO_SITE} | {CHRISCO_NUMBER}</div>
                    <div className="float-right contentHeaderSubtitle"><FaInstagramSquare />{CHRISCO_INSTAGRAM} <FaFacebook />{CHRISCO_FACEBOOK}</div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card color="light" className="homeCard">
                        <CardBody>
                            <CardTitle tag="h4">Welcome to the <span style={{color: "red"}}>Chrisco PrePaid Mastercard®</span> Gift Card portal.</CardTitle>
                            <CardText>
                                <div><p>The letter that came with your card includes your <span style={{color: "red"}}>Account ID</span>, and a <span style={{color: "red"}}>PIN</span> number.  Please keep this letter in a safe place so you know you don’t forget your PIN! See below and follow the prompts to <span style={{color: "red"}}>Activate</span> your card, or review and <span style={{color: "red"}}>View Transactions</span> and the card balance.</p></div>
                                <div><p>If you need to contact us or to order cards see <a href={CARD_LINK}>www.chrisco.com.au</a> or call on 1800 830 830.</p></div>
                                <div><p>Enjoy your gift card!</p></div>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card color="light" className="homeCard">
                        <CardBody>
                            <CardText>
                                <div>
                                    If you wish to view the balance and transactions for an existing Chrisco PrePaid Mastercard, select the View Transactions button below
                                </div>
                                <div>
                                    If you wish to start the process of activating a new Chrisco PrePaid Mastercard, click the activate button below
                                </div>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card color="light" className="homeCard">
                        <CardBody>
                            <CardTitle tag="h6">Please Select</CardTitle>
                            <Container fluid>
                                <Row>
                                    <Col xs="12" sm="6" md="4">
                                        <Button size="lg" color="danger" className='homeButtons' onClick={viewTransactions}>View Transactions</Button>
                                    </Col>
                                    <Col xs="12" sm="6" md="4">
                                        <Button size="lg" color="danger" className='homeButtons' onClick={startActivate}>Activate a Card</Button>
                                    </Col>
                                    <Col xs="12" sm="6" md="4">
                                        <Button size="lg" color="danger" target="_blank" className='homeButtons' href="https://pin.emerchants.com.au">Change Pin</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            

        </Container>
    )
}
