import React, { useEffect, useState } from "react";
import { BASEAPI, CHRISCO_NUMBER } from "../../constants";
import { PostJson } from "../../helpers/fetch_helpers";
import CardAccount from "../Card/CardAcount";
import { Alert, Button, Collapse, FormGroup, Input, Label, Spinner } from "reactstrap";
import { CardStatusEnum } from "../../enums";
import { FaAngleDoubleDown, FaAngleDoubleUp } from "react-icons/fa";

export default function ActivationCardList({memberDetail, eaid, nextStep}) {
    let [isLoading, setIsLoading] = useState(false);
    let [activatedCards, setActivatedCards] = useState();
    let [cardToActivate, setCardToActivate] = useState();
    let [selectedCards, setSelectedCards] = useState([]);
    let [showActivatedCards, setShowActivatedCards] = useState(false);
    let [cardActivationError, setCardActivationError] = useState();
    let [getCardError, setGetCardError] = useState();

    let anySelected = selectedCards && selectedCards.length > 0;

    async function getMembersCards() {
        setIsLoading(true);
        

        var uri = BASEAPI + "/api/member/GetMemberCardCollection";

        var data = {
            memberNumber: memberDetail.memberNumber,
            eaid: eaid
        };

        var result = await PostJson(uri, data)
            .catch(function(error){
                //console.log(error);
                setGetCardError("An error occurred getting your card information: " + error.message + ". If this continues to occur, please call " + CHRISCO_NUMBER + " to talk to one of our customer service agents.")
            })
            .finally(() => {
                setIsLoading(false);
            })

        //todo: verify results?
        setActivatedCards(result.previouslyActivatedCards)
        setCardToActivate(result.cardToActivate);
    }

    function selectionChanged(cardId, checked) {
        if (checked) {
            var selCards = selectedCards.concat(cardId);
            setSelectedCards(selCards);
        } else {
            var upCards = selectedCards.filter(x => x !== cardId);
            setSelectedCards(upCards);
        }
    }

    async function activateCards() {
        //automatically send if of toBeActivatedCard
        //send to server, on success move on to confirmation, on error show
        setCardActivationError(null);

        var uri = BASEAPI + "/api/member/ActivateCards";

        var data = { memberNumber: memberDetail.memberNumber,
                     cardsToActivate: [ cardToActivate.cardId ]
                  }


        var result = await PostJson(uri, data)
        .catch(function(error){
            //console.log(error);
            setCardActivationError("An error occurred activating your card: " + error.message + ". If this continues to occur, please call " + CHRISCO_NUMBER + " to talk to one of our customer service agents.")
        })

        if (result.success) {
            nextStep();
        }
    }

    function toggleShowActivated() {
        setShowActivatedCards(!showActivatedCards);
    }

    useEffect(() => {
        getMembersCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memberDetail])

    return (
        <div>
            <pre>EAID: {eaid}</pre>
            {isLoading && <Spinner color="dark" />}

            {getCardError && 
                            <Alert color="danger">{getCardError}</Alert>
                        }

            {cardToActivate &&
            <div>
                <div>
                    <h3>Card ready to activate</h3>
                    <CardAccount index={null} card={cardToActivate}></CardAccount>
                </div>
                <div>
                    <FormGroup>
                        <Button color="danger" onClick={activateCards}>Activate Card</Button>
                    </FormGroup>
                    {cardActivationError && 
                            <Alert color="danger">{cardActivationError}</Alert>
                        }
                </div>
            </div> }
            {activatedCards == null && !isLoading && !getCardError &&
                <div>No Active Cards</div>
            }
            {activatedCards && 
            <div>
                <Button size="small" color="secondary" onClick={toggleShowActivated}>{showActivatedCards ? 'Hide' : 'Show'} Previously Activated {showActivatedCards ? <FaAngleDoubleUp /> : <FaAngleDoubleDown />}</Button>
                <Collapse isOpen={showActivatedCards}>
                    <div className="formGroup">
                        <div className="groupTitle">Previously Activated Cards for: {memberDetail.fullName} [{memberDetail.memberNumber}]</div>

                        {activatedCards.map((card, index) => {
                            return <CardAccount key={index} index={index} card={card} selectionChanged={selectionChanged}></CardAccount>
                        })}
                    </div>
                </Collapse>
            </div>
            }
            {anySelected &&
            <div>
                <small>debug selected cards</small>
                <pre>{selectedCards.map((cardId, indx) => {
                    return (<span>{cardId}</span>)})
                }
                </pre>
            </div>}
        </div>
    )
}